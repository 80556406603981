"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'ErrorLog',
  data: function data() {
    return {
      dialogTableVisible: false
    };
  },
  computed: {
    errorLogs: function errorLogs() {
      return this.$store.getters.errorLogs;
    }
  },
  methods: {
    clearAll: function clearAll() {
      this.dialogTableVisible = false;
      this.$store.dispatch('errorLog/clearErrorLog');
    }
  }
};