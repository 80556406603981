var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "background" }, [
    _c(
      "div",
      [_c("el-container", [_c("el-header", [_vm._v("会員データ")])], 1)],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "app-container" },
      [
        _c(
          "el-form",
          {
            ref: "form",
            attrs: {
              model: _vm.form,
              rules: _vm.rules,
              "label-position": "top",
              "label-width": "250px",
            },
          },
          [
            _c(
              "div",
              [
                _c("hr", { staticClass: "divider" }),
                _vm._v(" "),
                _c("div", [_vm._v("個人情報")]),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c(
                  "el-row",
                  { attrs: { gutter: 20 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 15 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label:
                                "会員写真添付（任意）※写真１枚まで添付可能",
                            },
                          },
                          [
                            _c(
                              "el-upload",
                              {
                                ref: "upload",
                                staticClass: "upload",
                                attrs: {
                                  accept: " .jpg, .jpeg, .png",
                                  action: "",
                                  limit: 1,
                                  "on-change": _vm.handleAddAvatar,
                                  "on-remove": _vm.handleRemoveAvatar,
                                  "file-list": _vm.fileAvatar,
                                  "list-type": "picture",
                                  "auto-upload": false,
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      slot: "trigger",
                                      size: "small",
                                      type: "primary",
                                    },
                                    slot: "trigger",
                                  },
                                  [_vm._v("ファイルを選択")]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.fileAvatarOrg.length > 0 &&
                            _vm.fileAvatar.length === 0
                              ? _c("div", [
                                  _c("img", {
                                    staticClass: "preview",
                                    attrs: { src: _vm.fileAvatarOrg },
                                  }),
                                  _vm._v(" "),
                                  _c("span", {
                                    staticClass: "el-icon-circle-close",
                                    on: { click: _vm.handleDeleteAvatar },
                                  }),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  { attrs: { gutter: 50 } },
                  [
                    _c(
                      "el-col",
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: _vm.textOrganization,
                              error: _vm.errorMessageOrganization,
                              rules: { required: true },
                            },
                          },
                          [
                            _vm.companyMode === false
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          slot: "trigger",
                                          size: "small",
                                          type: "primary",
                                        },
                                        on: {
                                          click: _vm.swapAffiliationComponent,
                                        },
                                        slot: "trigger",
                                      },
                                      [_vm._v("組織を選択")]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.pathAffiliationList.length !== 0
                              ? _c(
                                  "el-card",
                                  { staticClass: "box-card" },
                                  _vm._l(
                                    _vm.pathAffiliationList,
                                    function (item, index) {
                                      return _c(
                                        "div",
                                        {
                                          key: index,
                                          staticClass: "pathAffiliation",
                                        },
                                        [
                                          _c(
                                            "el-col",
                                            {
                                              staticClass:
                                                "divideTheDistanceAffiliation",
                                              attrs: {
                                                span:
                                                  _vm.companyMode === false
                                                    ? 23
                                                    : null,
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "titleItemAffiliation",
                                                  style:
                                                    "margin-left:" +
                                                    item.paddingFloorInTreeAffiliation +
                                                    "px",
                                                },
                                                [_vm._v(_vm._s(item.name))]
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _vm.companyMode === false
                                            ? _c("span", {
                                                staticClass:
                                                  "el-icon-circle-close deleteItemAffiliation",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.deleteItemAffiliation(
                                                      item,
                                                      index
                                                    )
                                                  },
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  0
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "drawer", class: { open: _vm.drawer } },
                      [
                        _c("AffiliationGroup", {
                          key: _vm.keyAffiliation,
                          on: {
                            panretToggleDrawer: _vm.swapAffiliationComponent,
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  { attrs: { gutter: 50 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: { prop: "last_name", label: "会員名（姓）" },
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.form.last_name,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "last_name", $$v)
                                },
                                expression: "form.last_name",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { prop: "name", label: "会員名（名）" } },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.form.name,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "name", $$v)
                                },
                                expression: "form.name",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "company.join_date",
                              rules: {
                                required: true,
                                validator: _vm.checkDate,
                                colname: "入会年月日",
                              },
                              label: "入会年月日",
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "block" },
                              [
                                _c("el-date-picker", {
                                  attrs: { format: "yyyy-MM-dd", type: "date" },
                                  model: {
                                    value: _vm.form.company.join_date,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form.company,
                                        "join_date",
                                        $$v
                                      )
                                    },
                                    expression: "form.company.join_date",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  { attrs: { gutter: 50 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "last_name_furigana",
                              label: "会員名（ふりがな姓）",
                            },
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.form.last_name_furigana,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "last_name_furigana", $$v)
                                },
                                expression: "form.last_name_furigana",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "name_furigana",
                              label: "会員名（ふりがな名）",
                            },
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.form.name_furigana,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "name_furigana", $$v)
                                },
                                expression: "form.name_furigana",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  { attrs: { gutter: 50 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "position",
                              label: "役職名（任意）",
                            },
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.form.position,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "position", $$v)
                                },
                                expression: "form.position",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.zoneInfo.app_allow_show_birthday
                  ? _c(
                      "el-row",
                      { attrs: { gutter: 50 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  rules: {
                                    required: false,
                                    validator: null,
                                    colname: "生年月日",
                                  },
                                  label: "生年月日",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "block" },
                                  [
                                    _c("el-date-picker", {
                                      attrs: {
                                        "picker-options": _vm.futureTimeCheck,
                                        format: "yyyy-MM-dd",
                                        type: "date",
                                      },
                                      model: {
                                        value: _vm.form.birthday,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "birthday", $$v)
                                        },
                                        expression: "form.birthday",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.companyMode === false
                  ? _c(
                      "div",
                      [
                        _c(
                          "el-row",
                          { attrs: { gutter: 50 } },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { prop: "id", label: "会員ID" } },
                                  [
                                    _c(
                                      "span",
                                      { staticStyle: { color: "black" } },
                                      [_vm._v(_vm._s(_vm.form.id))]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-row",
                          { attrs: { gutter: 20 } },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 11 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "password",
                                      label: "会員パスワード",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-input",
                                      _vm._b(
                                        {
                                          key: _vm.passwordType,
                                          attrs: {
                                            type: _vm.passwordType,
                                            placeholder: "Password",
                                            name: "password",
                                          },
                                          model: {
                                            value: _vm.form.password,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "password",
                                                $$v
                                              )
                                            },
                                            expression: "form.password",
                                          },
                                        },
                                        "el-input",
                                        {
                                          disabled:
                                            _vm.disable_confirmed_password,
                                        },
                                        false
                                      )
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              { attrs: { span: 1 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticStyle: { visibility: "hidden" },
                                    attrs: { prop: "password", label: "_" },
                                  },
                                  [
                                    _c(
                                      "span",
                                      _vm._b(
                                        {
                                          staticClass: "show-pwd",
                                          on: { click: _vm.showPwd },
                                        },
                                        "span",
                                        { hidden: _vm.disable_password },
                                        false
                                      ),
                                      [
                                        _c("svg-icon", {
                                          staticStyle: {
                                            visibility: "visible",
                                            color: "black",
                                          },
                                          attrs: {
                                            "icon-class":
                                              _vm.passwordType === "password"
                                                ? "eye"
                                                : "eye-open",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-row",
                          { attrs: { gutter: 50 } },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "confirmation_password",
                                      label: "会員パスワード（確認用）",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-input",
                                      _vm._b(
                                        {
                                          key: _vm.passwordType,
                                          attrs: { type: _vm.passwordType },
                                          model: {
                                            value:
                                              _vm.form.confirmation_password,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "confirmation_password",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "form.confirmation_password",
                                          },
                                        },
                                        "el-input",
                                        { disabled: _vm.disable_password },
                                        false
                                      )
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-row",
                          [
                            _c("el-col", { attrs: { md: 12 } }, [
                              _c("label", [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.pass_check,
                                      expression: "pass_check",
                                    },
                                  ],
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    checked: Array.isArray(_vm.pass_check)
                                      ? _vm._i(_vm.pass_check, null) > -1
                                      : _vm.pass_check,
                                  },
                                  on: {
                                    click: _vm.activePwd,
                                    change: function ($event) {
                                      var $$a = _vm.pass_check,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            (_vm.pass_check = $$a.concat([$$v]))
                                        } else {
                                          $$i > -1 &&
                                            (_vm.pass_check = $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1)))
                                        }
                                      } else {
                                        _vm.pass_check = $$c
                                      }
                                    },
                                  },
                                }),
                                _vm._v("パスワードを変更する\n              "),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("br"),
                            _c("br"),
                            _vm._v(" "),
                            _c("span", { staticStyle: { color: "#f00" } }, [
                              _vm._v(
                                "※チェックを外している場合は、パスワードの更新が適用されません。"
                              ),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("br"),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("hr", { staticClass: "divider" }),
                _vm._v(" "),
                _c("div", [_vm._v("会社情報")]),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c(
                  "el-row",
                  { attrs: { gutter: 50 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "company.name",
                              rules: {
                                required: true,
                                validator: _vm.validateRequire,
                                colname: "会社名",
                              },
                              label: "会社名",
                            },
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.form.company.name,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form.company, "name", $$v)
                                },
                                expression: "form.company.name",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  { attrs: { gutter: 50 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "company.name_furigana",
                              rules: {
                                required: true,
                                validator: _vm.validateRequire,
                                colname: "会社名（ふりがな）",
                              },
                              label: "会社名（ふりがな）",
                            },
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.form.company.name_furigana,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form.company,
                                    "name_furigana",
                                    $$v
                                  )
                                },
                                expression: "form.company.name_furigana",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  { attrs: { gutter: 10 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 4 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            key: _vm.upload_postcode,
                            attrs: {
                              prop: "company.postcode1",
                              rules: [
                                {
                                  required: true,
                                  validator: _vm.validateRequire,
                                  colname: "郵便番号",
                                },
                                {
                                  required: false,
                                  validator: _vm.postcode1,
                                  colname: "",
                                },
                              ],
                              label: "郵便番号",
                            },
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.form.company.postcode1,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form.company, "postcode1", $$v)
                                },
                                expression: "form.company.postcode1",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 1, align: "center" } },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { visibility: "hidden" },
                            attrs: { label: "-" },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  visibility: "visible",
                                  color: "black",
                                },
                              },
                              [_vm._v("-")]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 4 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            key: _vm.upload_postcode,
                            attrs: {
                              prop: "company.postcode2",
                              rules: [
                                {
                                  required: false,
                                  validator: _vm.validateRequire,
                                  colname: "郵便番号",
                                },
                                {
                                  required: false,
                                  validator: _vm.postcode2,
                                  colname: "",
                                },
                              ],
                              label: _vm.space,
                            },
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.form.company.postcode2,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form.company, "postcode2", $$v)
                                },
                                expression: "form.company.postcode2",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  { attrs: { gutter: 50 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "company.address_1",
                              rules: {
                                required: true,
                                validator: _vm.validateRequire,
                                colname: "会社所在地",
                              },
                              label: "会社所在地",
                            },
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.form.company.address_1,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form.company, "address_1", $$v)
                                },
                                expression: "form.company.address_1",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  { attrs: { gutter: 50 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "company.address_2",
                              label: "ビル名（任意）",
                            },
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.form.company.address_2,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form.company, "address_2", $$v)
                                },
                                expression: "form.company.address_2",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  { attrs: { gutter: 10 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 4 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "company.phone_number1",
                              rules: [
                                {
                                  required: true,
                                  validator: _vm.validateRequirePhone,
                                  colname: "電話番号",
                                },
                                {
                                  required: false,
                                  validator: _vm.tel1,
                                  colname: "",
                                },
                              ],
                              label: "電話番号",
                            },
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.form.company.phone_number1,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form.company,
                                    "phone_number1",
                                    $$v
                                  )
                                },
                                expression: "form.company.phone_number1",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 1, align: "center" } },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { visibility: "hidden" },
                            attrs: { label: "-" },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  visibility: "visible",
                                  color: "black",
                                },
                              },
                              [_vm._v("-")]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 4 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "company.phone_number2",
                              rules: [
                                {
                                  required: false,
                                  validator: _vm.validateRequirePhone,
                                  colname: "電話番号",
                                },
                                {
                                  required: false,
                                  validator: _vm.tel2,
                                  colname: "",
                                },
                              ],
                              label: _vm.space,
                            },
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.form.company.phone_number2,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form.company,
                                    "phone_number2",
                                    $$v
                                  )
                                },
                                expression: "form.company.phone_number2",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 1, align: "center" } },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { visibility: "hidden" },
                            attrs: { label: "-" },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  visibility: "visible",
                                  color: "black",
                                },
                              },
                              [_vm._v("-")]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 4 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "company.phone_number3",
                              rules: [
                                {
                                  required: false,
                                  validator: _vm.validateRequirePhone,
                                  colname: "電話番号",
                                },
                                {
                                  required: false,
                                  validator: _vm.tel3,
                                  colname: "",
                                },
                              ],
                              label: _vm.space,
                            },
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.form.company.phone_number3,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form.company,
                                    "phone_number3",
                                    $$v
                                  )
                                },
                                expression: "form.company.phone_number3",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  { attrs: { gutter: 10 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 4 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            key: _vm.upload_fax,
                            attrs: {
                              prop: "company.fax1",
                              rules: [
                                {
                                  required: false,
                                  validator: _vm.validateRequireFax,
                                  colname: "FAX",
                                },
                                {
                                  required: false,
                                  validator: _vm.tel1,
                                  colname: "",
                                },
                              ],
                              label: "FAX（任意）",
                            },
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.form.company.fax1,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form.company, "fax1", $$v)
                                },
                                expression: "form.company.fax1",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 1, align: "center" } },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { visibility: "hidden" },
                            attrs: { label: "-" },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  visibility: "visible",
                                  color: "black",
                                },
                              },
                              [_vm._v("-")]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 4 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            key: _vm.upload_fax,
                            attrs: {
                              prop: "company.fax2",
                              rules: [
                                {
                                  required: false,
                                  validator: _vm.validateRequireFax,
                                  colname: "FAX",
                                },
                                {
                                  required: false,
                                  validator: _vm.tel2,
                                  colname: "",
                                },
                              ],
                              label: _vm.space,
                            },
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.form.company.fax2,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form.company, "fax2", $$v)
                                },
                                expression: "form.company.fax2",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 1, align: "center" } },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { visibility: "hidden" },
                            attrs: { label: "-" },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  visibility: "visible",
                                  color: "black",
                                },
                              },
                              [_vm._v("-")]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 4 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            key: _vm.upload_fax,
                            attrs: {
                              prop: "company.fax3",
                              rules: [
                                {
                                  required: false,
                                  validator: _vm.validateRequireFax,
                                  colname: "FAX",
                                },
                                {
                                  required: false,
                                  validator: _vm.tel3,
                                  colname: "",
                                },
                              ],
                              label: _vm.space,
                            },
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.form.company.fax3,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form.company, "fax3", $$v)
                                },
                                expression: "form.company.fax3",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  { attrs: { gutter: 10 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 4 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            key: _vm.upload_mobile,
                            attrs: {
                              prop: "company.mobile_phone1",
                              rules: [
                                {
                                  required: false,
                                  validator: _vm.validateRequireMobile,
                                  colname: "携帯電話",
                                },
                                {
                                  required: false,
                                  validator: _vm.tel1,
                                  colname: "",
                                },
                              ],
                              label: "携帯電話（任意）",
                            },
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.form.company.mobile_phone1,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form.company,
                                    "mobile_phone1",
                                    $$v
                                  )
                                },
                                expression: "form.company.mobile_phone1",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 1, align: "center" } },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { visibility: "hidden" },
                            attrs: { label: "-" },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  visibility: "visible",
                                  color: "black",
                                },
                              },
                              [_vm._v("-")]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 4 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            key: _vm.upload_mobile,
                            attrs: {
                              prop: "company.mobile_phone2",
                              rules: [
                                {
                                  required: false,
                                  validator: _vm.validateRequireMobile,
                                  colname: "携帯電話",
                                },
                                {
                                  required: false,
                                  validator: _vm.tel2,
                                  colname: "",
                                },
                              ],
                              label: _vm.space,
                            },
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.form.company.mobile_phone2,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form.company,
                                    "mobile_phone2",
                                    $$v
                                  )
                                },
                                expression: "form.company.mobile_phone2",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 1, align: "center" } },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { visibility: "hidden" },
                            attrs: { label: "-" },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  visibility: "visible",
                                  color: "black",
                                },
                              },
                              [_vm._v("-")]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 4 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            key: _vm.upload_mobile,
                            attrs: {
                              prop: "company.mobile_phone3",
                              rules: [
                                {
                                  required: false,
                                  validator: _vm.validateRequireMobile,
                                  colname: "携帯電話",
                                },
                                {
                                  required: false,
                                  validator: _vm.tel3,
                                  colname: "",
                                },
                              ],
                              label: _vm.space,
                            },
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.form.company.mobile_phone3,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form.company,
                                    "mobile_phone3",
                                    $$v
                                  )
                                },
                                expression: "form.company.mobile_phone3",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  { attrs: { gutter: 50 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "company.email",
                              rules: {
                                required: false,
                                validator: _vm.email,
                                colname: "Email",
                              },
                              label: "Email（任意）",
                            },
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.form.company.email,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form.company, "email", $$v)
                                },
                                expression: "form.company.email",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  { attrs: { gutter: 50 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "company.website",
                              rules: {
                                required: false,
                                validator: _vm.url,
                                colname: "ホームページ",
                              },
                              label: "ホームページ（任意）",
                            },
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.form.company.website,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form.company, "website", $$v)
                                },
                                expression: "form.company.website",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.disable_industries == true
                  ? _c(
                      "el-row",
                      { attrs: { gutter: 50 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "company.industries.group_id",
                                  label: "業種（大分類）",
                                },
                              },
                              [
                                _c("font", { attrs: { color: "#000;" } }, [
                                  _vm._v(_vm._s(_vm.majorName)),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 16 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "company.industry",
                                  label: "業種（中分類）",
                                },
                              },
                              [
                                _c("font", { attrs: { color: "#000;" } }, [
                                  _vm._v(
                                    _vm._s(_vm.form.company.industries.name)
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _c(
                      "el-row",
                      { attrs: { gutter: 50 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "company.industries.group_id",
                                  rules: {
                                    required: true,
                                    validator: _vm.validateRequireOption,
                                    colname: "業種（大分類）",
                                  },
                                  label: "業種（大分類）",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  _vm._b(
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: { placeholder: "大分類を選択" },
                                      on: { change: _vm.changeIndustryMajor },
                                      model: {
                                        value:
                                          _vm.form.company.industries.group_id,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form.company.industries,
                                            "group_id",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.company.industries.group_id",
                                      },
                                    },
                                    "el-select",
                                    { disabled: _vm.disable_industries },
                                    false
                                  ),
                                  _vm._l(_vm.industry_major, function (item) {
                                    return _c("el-option", {
                                      key: item.no,
                                      attrs: {
                                        label: item.title,
                                        value: item.no,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 16 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "company.industry",
                                  rules: {
                                    required: true,
                                    validator: _vm.validateRequireOption,
                                    colname: "業種（中分類）",
                                  },
                                  label: "業種（中分類）",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  _vm._b(
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: { placeholder: "中分類を選択" },
                                      model: {
                                        value: _vm.form.company.industry,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form.company,
                                            "industry",
                                            $$v
                                          )
                                        },
                                        expression: "form.company.industry",
                                      },
                                    },
                                    "el-select",
                                    { disabled: _vm.disable_industries },
                                    false
                                  ),
                                  _vm._l(_vm.industry_middle, function (item) {
                                    return _c("el-option", {
                                      key: item.no,
                                      attrs: {
                                        label: item.title,
                                        value: item.no,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      prop: "company.business_descriptions",
                      rules: {
                        required: true,
                        validator: _vm.validateRequire,
                        colname: "事業内容",
                      },
                      label: "事業内容（全角256文字まで入力可能）",
                    },
                  },
                  [
                    _c("el-input", {
                      attrs: { type: "textarea", rows: 5, maxlength: "256" },
                      model: {
                        value: _vm.form.company.business_descriptions,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.form.company,
                            "business_descriptions",
                            $$v
                          )
                        },
                        expression: "form.company.business_descriptions",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  { attrs: { gutter: 20 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "company.established",
                              label: "設立年月日（任意）",
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "block" },
                              [
                                _c("el-date-picker", {
                                  attrs: { format: "yyyy-MM-dd", type: "date" },
                                  model: {
                                    value: _vm.form.company.established,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form.company,
                                        "established",
                                        $$v
                                      )
                                    },
                                    expression: "form.company.established",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "company.capital_stock",
                              rules: {
                                validator: _vm.checkNumber,
                                colname: "資本金（任意）",
                              },
                              label: "資本金（任意）",
                            },
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.form.company.capital_stock,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form.company,
                                    "capital_stock",
                                    $$v
                                  )
                                },
                                expression: "form.company.capital_stock",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 2 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { visibility: "hidden" },
                            attrs: { label: "万円" },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  visibility: "visible",
                                  color: "black",
                                },
                              },
                              [_vm._v("万円")]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  { attrs: { gutter: 20 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "company.employees",
                              rules: {
                                validator: _vm.checkNumber,
                                colname: "社員数（任意）",
                              },
                              label: "社員数（任意）",
                            },
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.form.company.employees,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form.company, "employees", $$v)
                                },
                                expression: "form.company.employees",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 2 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { visibility: "hidden" },
                            attrs: { label: "人" },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  visibility: "visible",
                                  color: "black",
                                },
                              },
                              [_vm._v("人")]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "company.employees_part_time",
                              rules: {
                                validator: _vm.checkNumber,
                                colname: "パート・アルバイト数（任意）",
                              },
                              label: "パート・アルバイト数（任意）",
                            },
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.form.company.employees_part_time,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form.company,
                                    "employees_part_time",
                                    $$v
                                  )
                                },
                                expression: "form.company.employees_part_time",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 2 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { visibility: "hidden" },
                            attrs: { label: "人" },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  visibility: "visible",
                                  color: "black",
                                },
                              },
                              [_vm._v("人")]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _vm.companyMode === false
              ? _c(
                  "div",
                  [
                    _c("hr", { staticClass: "divider" }),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { prop: "is_active", label: "会員の管理" } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 10 } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  placeholder: "必ず一つ選択してください",
                                },
                                model: {
                                  value: _vm.form.is_active,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "is_active", $$v)
                                  },
                                  expression: "form.is_active",
                                },
                              },
                              _vm._l(_vm.logins, function (item) {
                                return _c("el-option", {
                                  key: item.no,
                                  attrs: { label: item.title, value: item.no },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.companyMode === false
              ? _c(
                  "div",
                  [
                    _c("hr", { staticClass: "divider" }),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          prop: "is_display",
                          label: "アプリへの会員表示の有無",
                        },
                      },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 10 } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  placeholder: "必ず一つ選択してください",
                                },
                                model: {
                                  value: _vm.form.is_display,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "is_display", $$v)
                                  },
                                  expression: "form.is_display",
                                },
                              },
                              _vm._l(_vm.displays, function (item) {
                                return _c("el-option", {
                                  key: item.no,
                                  attrs: { label: item.title, value: item.no },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]
        ),
        _vm._v(" "),
        _c(
          "el-button",
          {
            staticClass: "midButton",
            attrs: { type: "primary" },
            on: { click: _vm.postFromCheck },
          },
          [_vm._v("更新")]
        ),
        _vm._v(" "),
        _c(
          "el-dialog",
          {
            attrs: {
              title: "確認",
              visible: _vm.updateDialogVisible,
              width: "30%",
              center: "",
            },
            on: {
              "update:visible": function ($event) {
                _vm.updateDialogVisible = $event
              },
            },
          },
          [
            _c("span", [_vm._v("会員情報を更新します。よろしいですか？")]),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _c(
                  "el-button",
                  {
                    on: {
                      click: function ($event) {
                        _vm.updateDialogVisible = false
                      },
                    },
                  },
                  [_vm._v("いいえ")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  { attrs: { type: "primary" }, on: { click: _vm.onUpdate } },
                  [_vm._v("はい")]
                ),
              ],
              1
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "el-dialog",
          {
            attrs: {
              title: "確認",
              visible: _vm.waitDialogVisible,
              width: "30%",
              "close-on-click-modal": false,
              center: "",
            },
            on: {
              "update:visible": function ($event) {
                _vm.waitDialogVisible = $event
              },
            },
          },
          [_c("span", [_vm._v("データを登録中です。")])]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }