"use strict";

var _interopRequireDefault = require("/Users/vagrant/git/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.decrypt = decrypt;
exports.encrypt = encrypt;
exports.getInfo = getInfo;
exports.login = login;
exports.logout = logout;
exports.updateUid = updateUid;
require("core-js/modules/es6.regexp.to-string");
var _request = _interopRequireDefault(require("@/utils/request"));
require("firebase/auth");
// import firebase from 'firebase/app'

/**
 * ログイン処理
 * @param {username, password} data
 */
function login(data) {
  var token = {
    token: 'admin-token',
    id: data.username,
    password: data.password
  };
  return {
    code: 20000,
    data: token
  };
  /*
    // const username = process.env.VUE_APP_FIREBASE_DEFAULT_NAME
    // const password = process.env.VUE_APP_FIREBASE_DEFAULT_PASSWORD
    return firebase.auth().signInWithEmailAndPassword(
      data.username,
      data.password
    ).catch((err) => {
      console.log(err.message)
    }).then((response) => {
      if (response !== undefined) {
        var token = {
          token: 'admin-token',
          uid: response.user.uid,
          email: data.username
        }
        return {
          code: 20000,
          data: token
        }
      }
    })
    */
}

/**
 * 内部的なログイン処理
 * @param {username, password} data
 */
/*
function loginInternal(data) {
  return request({
    url: '/user/login',
    method: 'post',
    data
  })
}
*/
/**
 *  ユーザロール
 *  admin, editor
 */
var users_info = {
  'admin-token': {
    roles: ['admin'],
    avatar: 'https://storage.googleapis.com/' + process.env.VUE_APP_GCS_BUCKET + '/assets/parson_no-image.png',
    introduction: 'admin',
    name: 'Super Admin'
  },
  'editor-token': {
    roles: ['editor'],
    avatar: 'https://storage.googleapis.com/' + process.env.VUE_APP_GCS_BUCKET + '/assets/parson_no-image.png',
    introduction: 'editor',
    name: 'Normal Editor'
  }
};
var crypto = require('crypto');
var ENCRYPTION_KEY = 's80102a54eb75cd793d23c38b1fg8010';
function encrypt(text) {
  // var cipher = crypto.createCipheriv('aes-256-cbc', Buffer.from(ENCRYPTION_KEY), new TextEncoder('utf-8').encode('s80102a54eb75cd7'))
  var cipher = crypto.createCipheriv('aes-256-cbc', Buffer.from(ENCRYPTION_KEY), unescape(encodeURIComponent('s80102a54eb75cd7')));
  var encrypted = cipher.update(text);
  encrypted = Buffer.concat([encrypted, cipher.final()]);
  return encrypted.toString('base64');
}
function decrypt(text) {
  var decipher = crypto.createDecipheriv('aes-256-cbc', Buffer.from(ENCRYPTION_KEY), new TextEncoder('utf-8').encode('s80102a54eb75cd7'));
  var decrypted = decipher.update(Buffer.from(text, 'base64'));
  decrypted = Buffer.concat([decrypted, decipher.final()]);
  return decrypted.toString();
}
function getInfo(token) {
  var info = users_info[token];
  return new Promise(function (resolve) {
    var result = {
      code: 20000,
      data: info
    };
    resolve(result);
  });
}
function logout() {
  return new Promise(function (resolve) {
    var result = {
      code: 20000,
      data: 'success'
    };
    resolve(result);
  });
}
function updateUid(data) {
  return (0, _request.default)({
    url: '/adminLogin',
    method: 'post',
    data: data
  });
}