var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "background" }, [
    _c(
      "div",
      { staticClass: "app-container" },
      [
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "importForm",
                  attrs: {
                    model: _vm.form,
                    "label-position": "top",
                    "label-width": "120px",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        {
                          staticStyle: { color: "#888", "font-size": "20px" },
                          attrs: { span: 40 },
                        },
                        [
                          _vm._v(
                            "\n              会員情報の取込み  \n            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 4 } },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "sample",
                              attrs: {
                                size: "mini",
                                type: "primary",
                                plain: "",
                              },
                              on: { click: _vm.download },
                            },
                            [_vm._v("サンプル")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("div", [
                    _c("header", { staticClass: "sub-header" }, [
                      _vm._v("EXCELファイル"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { md: 15 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "excelFile",
                                rules: _vm.rules.excelFile,
                              },
                            },
                            [
                              _c(
                                "el-upload",
                                {
                                  ref: "upload",
                                  staticClass: "upload",
                                  attrs: {
                                    accept: " .xlsx",
                                    action: "",
                                    "on-change": _vm.handleAddExcelFile,
                                    "on-remove": _vm.handleRemoveExcelFile,
                                    "file-list": _vm.excelFile,
                                    limit: 1,
                                    "list-type": "excel",
                                    "auto-upload": false,
                                  },
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        slot: "trigger",
                                        size: "small",
                                        type: "primary",
                                      },
                                      slot: "trigger",
                                    },
                                    [_vm._v("ファイルを選択")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c(
                        "el-form",
                        [
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "midButton",
                                  attrs: {
                                    type: "primary",
                                    disabled: _vm.centerDialogVisible,
                                  },
                                  on: { click: _vm.submitUpload },
                                },
                                [_vm._v("取込み")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c(
                        "el-dialog",
                        {
                          attrs: {
                            title: "確認",
                            visible: _vm.centerDialogVisible,
                            width: "30%",
                            "close-on-click-modal": false,
                            center: "",
                          },
                          on: {
                            "update:visible": function ($event) {
                              _vm.centerDialogVisible = $event
                            },
                          },
                        },
                        [_c("span", [_vm._v("データをインポート中です。")])]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("hr", { staticClass: "divider" }),
              _vm._v(" "),
              _c("div", [
                _c("p", [_vm._v("＜取り込む際の注意点など＞")]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "・入会年月日や設立年月日の入力形式は以下のように入力をお願いします。"
                  ),
                ]),
                _vm._v(" "),
                _c("p", [_vm._v("    例）2022年05月01日の場合")]),
                _vm._v(" "),
                _c("p", [_vm._v("    2022/5/1")]),
                _vm._v(" "),
                _c("p", [_vm._v("・資本金の単位は万円になります。")]),
                _vm._v(" "),
                _c("p", [_vm._v("    例）100万円の場合")]),
                _vm._v(" "),
                _c("p", [_vm._v("    100")]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "・「サンプル」ボタンをクリックしてダウンロードしたファイル(" +
                      _vm._s(_vm.introduceSample.importSampleName) +
                      ")に必要事項を入力してください。"
                  ),
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "    ２行目は参考データの為、実際の「取込み」時は行ごと削除をお願いします。"
                  ),
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "    背景色：黄色のセルは必須入力項目となっております。"
                  ),
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "・郵便番号や住所などの数値は半角で入力してください。"
                  ),
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "・所属IDは＜所属ID対応表＞をご参照の上、半角で入力してください。"
                  ),
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "・各(" +
                      _vm._s(_vm.introduceSample.textProkuShibu) +
                      ")内の(" +
                      _vm._s(_vm.introduceSample.textShibuChiku) +
                      ")または委員会等を登録する場合は、該当する(" +
                      _vm._s(_vm.introduceSample.textShibuChiku) +
                      ")"
                  ),
                ]),
                _c("p", { staticStyle: { "margin-left": "15px" } }, [
                  _vm._v("または委員会などの所属IDのみを入力してください。"),
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "・委員会を登録する場合、上位の地区や支部を入力する必要はございません。"
                  ),
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "・複数の所属IDを入力する際は半角のカンマ「,」で区切って入力してください。"
                  ),
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v("(例）"),
                  _vm.introduceSample.exampleName1
                    ? _c("span", [
                        _vm._v(_vm._s(_vm.introduceSample.exampleName1) + "の"),
                      ])
                    : _vm._e(),
                  _vm.introduceSample.exampleName2
                    ? _c("span", [
                        _vm._v("「" + _vm._s(_vm.introduceSample.exampleName2)),
                      ])
                    : _vm._e(),
                  _vm._v(
                    "(" +
                      _vm._s(_vm.introduceSample.exampleNumber1) +
                      ")」の場合 " +
                      _vm._s(_vm.introduceSample.exampleNumber1)
                  ),
                ]),
                _vm._v(" "),
                _vm.introduceSample.exampleName4 &&
                _vm.introduceSample.exampleNumber3
                  ? _c("p", [
                      _vm._v(
                        "(例）「" +
                          _vm._s(_vm.introduceSample.exampleName3) +
                          "(" +
                          _vm._s(_vm.introduceSample.exampleNumber2) +
                          ")」と「" +
                          _vm._s(_vm.introduceSample.exampleName4) +
                          "(" +
                          _vm._s(_vm.introduceSample.exampleNumber3) +
                          ")」の場合 " +
                          _vm._s(_vm.introduceSample.exampleNumber2) +
                          "," +
                          _vm._s(_vm.introduceSample.exampleNumber3)
                      ),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("hr", { staticClass: "divider" }),
              _vm._v(" "),
              _c("p", [_vm._v("＜所属ID対応表＞")]),
              _vm._v(" "),
              _c("div", { staticClass: "custom-tree-container" }, [
                _c(
                  "div",
                  { staticClass: "block" },
                  [
                    _c(
                      "el-row",
                      { staticClass: "titleTable" },
                      [
                        _c("el-col", { attrs: { span: 12 } }, [
                          _c("div", { staticClass: "grid-content-left" }, [
                            _c("span", [_vm._v("組織")]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("el-col", { attrs: { span: 12 } }, [
                          _c("div", { staticClass: "grid-content" }, [
                            _c("span", [_vm._v("ID")]),
                          ]),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("el-tree", {
                      attrs: {
                        data: _vm.data,
                        "node-key": "id",
                        "default-expand-all": "",
                        "expand-on-click-node": false,
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (ref) {
                            var node = ref.node
                            var data = ref.data
                            return _c(
                              "span",
                              { staticClass: "custom-tree-node" },
                              [
                                _c("span", [_vm._v(_vm._s(node.label))]),
                                _vm._v(" "),
                                _c("span", { staticClass: "organizationId" }, [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(data.id) +
                                      "\n                "
                                  ),
                                ]),
                              ]
                            )
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }