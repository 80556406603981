"use strict";

var _interopRequireDefault = require("/Users/vagrant/git/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
var _SocialSignin = _interopRequireDefault(require("./components/SocialSignin"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import { validUsername } from '@/utils/validate'
var _default = exports.default = {
  name: 'Login',
  components: {
    SocialSign: _SocialSignin.default
  },
  data: function data() {
    var validateUsername = function validateUsername(rule, value, callback) {
      if (!value) {
        callback(new Error(rule.colname + 'を入力してください'));
      }
      callback();
    };
    var validatePassword = function validatePassword(rule, value, callback) {
      if (!value) {
        callback(new Error(rule.colname + 'を入力してください'));
      } else if (value.length < 4) {
        callback(new Error(rule.colname + 'は4桁以上で入力してください'));
      }
      callback();
    };
    return {
      errorMessage: '',
      loginForm: {
        id: '',
        password: ''
      },
      loginRules: {
        id: [{
          required: true,
          trigger: 'blur',
          validator: validateUsername,
          colname: 'ID'
        }],
        password: [{
          required: true,
          trigger: 'blur',
          validator: validatePassword,
          colname: 'パスワード'
        }]
      },
      passwordType: 'password',
      capsTooltip: false,
      loading: false,
      showDialog: false,
      redirect: undefined,
      otherQuery: {},
      validateUsername: function validateUsername(rule, value, callback) {
        if (!value) {
          callback(new Error(rule.colname + 'を入力してください'));
        }
        callback();
      },
      validatePassword: function validatePassword(rule, value, callback) {
        if (!value) {
          callback(new Error(rule.colname + 'を入力してください'));
        } else if (value.length < 4) {
          callback(new Error(rule.colname + 'は4桁以上で入力してください'));
        }
        callback();
      }
    };
  },
  watch: {
    $route: {
      handler: function handler(route) {
        var query = route.query;
        if (query) {
          this.redirect = query.redirect;
          this.otherQuery = this.getOtherQuery(query);
        }
      },
      immediate: true
    }
  },
  created: function created() {
    // window.addEventListener('storage', this.afterQRScan)
  },
  mounted: function mounted() {
    if (this.loginForm.id === '') {
      this.$refs.id.focus();
    } else if (this.loginForm.password === '') {
      this.$refs.password.focus();
    }
  },
  destroyed: function destroyed() {
    // window.removeEventListener('storage', this.afterQRScan)
  },
  methods: {
    checkCapslock: function checkCapslock() {
      var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
        shiftKey = _ref.shiftKey,
        key = _ref.key;
      if (key && key.length === 1) {
        if (shiftKey && key >= 'a' && key <= 'z' || !shiftKey && key >= 'A' && key <= 'Z') {
          this.capsTooltip = true;
        } else {
          this.capsTooltip = false;
        }
      }
      if (key === 'CapsLock' && this.capsTooltip === true) {
        this.capsTooltip = false;
      }
    },
    showPwd: function showPwd() {
      var _this = this;
      if (this.passwordType === 'password') {
        this.passwordType = '';
      } else {
        this.passwordType = 'password';
      }
      this.$nextTick(function () {
        _this.$refs.password.focus();
      });
    },
    handleLogin: function handleLogin() {
      var _this2 = this;
      this.$refs.loginForm.validate(function (valid) {
        if (valid) {
          _this2.loading = true;
          _this2.errorMessage = '';
          _this2.$store.dispatch('user/login', _this2.loginForm).then(function () {
            if (_this2.$store.getters.redirect.length > 0) {
              if (_this2.$store.getters.redirect === 'non_active') {
                _this2.loading = false;
                _this2.errorMessage = 'ログイン権限がありません';
              } else {
                _this2.redirect = _this2.$store.getters.redirect;
                _this2.$router.push({
                  path: _this2.redirect || '/',
                  query: _this2.otherQuery
                });
                _this2.loading = false;
              }
            } else {
              _this2.loading = false;
              _this2.errorMessage = 'パスワードが一致しないか、ユーザー登録がされていません。';
            }
          }).catch(function () {
            _this2.errorMessage = 'パスワードが一致しないか、ユーザー登録がされていません。';
            _this2.loading = false;
          });
        } else {
          // console.log('error submit!!')
          return false;
        }
      });
    },
    getOtherQuery: function getOtherQuery(query) {
      return Object.keys(query).reduce(function (acc, cur) {
        if (cur !== 'redirect') {
          acc[cur] = query[cur];
        }
        return acc;
      }, {});
    } // afterQRScan() {
    //   if (e.key === 'x-admin-oauth-code') {
    //     const code = getQueryObject(e.newValue)
    //     const codeMap = {
    //       wechat: 'code',
    //       tencent: 'code'
    //     }
    //     const type = codeMap[this.auth_type]
    //     const codeName = code[type]
    //     if (codeName) {
    //       this.$store.dispatch('LoginByThirdparty', codeName).then(() => {
    //         this.$router.push({ path: this.redirect || '/' })
    //       })
    //     } else {
    //       alert('第三方登录失败')
    //     }
    //   }
    // }
  }
};