"use strict";

var _interopRequireDefault = require("/Users/vagrant/git/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ZONE_INFO_AND_INDUSTRY_ZONE = exports.USER_AFFILIATION_LIST = exports.SEARCH_MEMBERS_WITH_AFFILIATION = exports.INDUSTRY_MIDDLE_LIST = exports.INDUSTRY_MIDDLE_INFO = exports.INDUSTRY_MAJOR_LIST = exports.GET_USER_EXIST_BY_ID = exports.GET_MEMBER_ALL = exports.GET_MEMBER = exports.GET_INDUSTRIES = exports.AFFILIATION_LIST = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("/Users/vagrant/git/node_modules/@babel/runtime-corejs2/helpers/taggedTemplateLiteral.js"));
var _graphqlTag = _interopRequireDefault(require("graphql-tag"));
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11;
var GET_MEMBER = exports.GET_MEMBER = (0, _graphqlTag.default)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\nquery getMyInformation($uid: uuid) {\n  user(where: {uid: {_eq: $uid}}) {\n    avatar\n    company_id\n    last_name\n    last_name_furigana\n    name\n    birthday\n    name_furigana\n    position\n    mobile_phone\n    uid\n    id\n    password\n    is_display\n    is_active\n    company {\n      id\n      area\n      website\n      business_descriptions\n      capital_stock\n      created_at\n      email\n      employees\n      established\n      employees_part_time\n      fax\n      name\n      name_furigana\n      address_1\n      address_2\n      phone_number\n      join_date\n      mobile_phone\n      postcode\n      industry\n      areaByArea {\n        id\n        name\n      }\n      industries {\n        id\n        name\n        code\n        group_id\n      }\n      company_area_branch {\n        branchByBranch {\n          name\n          id\n        }\n      }\n    }\n    user_affiliations {\n      affiliation_id\n      affiliation {\n        name\n        parent_id\n      }\n    }\n  }\n}\n"])));
var GET_MEMBER_ALL = exports.GET_MEMBER_ALL = (0, _graphqlTag.default)(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\nquery {\n  user {\n    id\n    uid\n  }\n}\n"])));
var GET_INDUSTRIES = exports.GET_INDUSTRIES = (0, _graphqlTag.default)(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)(["\nquery {\n  industries{\n    code\n  }\n}\n"])));
var SEARCH_MEMBERS_WITH_AFFILIATION = exports.SEARCH_MEMBERS_WITH_AFFILIATION = (0, _graphqlTag.default)(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2.default)(["\nquery ($search_word: String, $group_industry_id: Int, $industry_code: String, $page_top: Int, $page_size: Int, $affiliations: String) {\n  search_members_with_affiliation(args: {search_word: $search_word, group_industry_id: $group_industry_id, industry_code: $industry_code, page_top: $page_top, page_size: $page_size, affiliations: $affiliations}) {\n    id\n    uid\n    frist_name: name\n    frist_name_kana: name_furigana\n    last_name: last_name\n    last_name_kana: last_name_furigana\n    company {\n      company_name: name\n      industries {\n        group_id\n        code\n        industry_name: name\n      }\n    }\n    user_affiliations {\n      affiliation_id\n      user_id\n      affiliation {\n        name\n        parent_id\n        is_main\n      }\n    }\n  }\n  search_members_with_affiliation_aggregate(args: {search_word: $search_word, group_industry_id: $group_industry_id, industry_code: $industry_code, affiliations: $affiliations, page_top: null, page_size: null}) {\n    aggregate {\n      count\n    }\n  }\n}\n"])));
var INDUSTRY_MAJOR_LIST = exports.INDUSTRY_MAJOR_LIST = (0, _graphqlTag.default)(_templateObject5 || (_templateObject5 = (0, _taggedTemplateLiteral2.default)(["\nquery {\n  industry_group(order_by: {id: asc}) {\n    id\n    name\n  }\n}\n"])));
var INDUSTRY_MIDDLE_LIST = exports.INDUSTRY_MIDDLE_LIST = (0, _graphqlTag.default)(_templateObject6 || (_templateObject6 = (0, _taggedTemplateLiteral2.default)(["\nquery($group_id: Int ) {\n  industries(where: {group_id: {_eq: $group_id}}, order_by: {code: asc}) {\n    code\n    name\n  }\n}\n"])));
var INDUSTRY_MIDDLE_INFO = exports.INDUSTRY_MIDDLE_INFO = (0, _graphqlTag.default)(_templateObject7 || (_templateObject7 = (0, _taggedTemplateLiteral2.default)(["\nquery($code: String ) {\n  industries(where: {code: {_eq: $code}}, order_by: {id: asc}) {\n    id\n    name\n    code\n    group_id\n  }\n}\n"])));
var AFFILIATION_LIST = exports.AFFILIATION_LIST = (0, _graphqlTag.default)(_templateObject8 || (_templateObject8 = (0, _taggedTemplateLiteral2.default)(["\nquery getAffiliation {\n  affiliation(where: {parent_id: {_is_null: true}, is_display: {_eq: true}}, order_by: {order: asc}) {\n    ...affiliationFragment\n    affiliations(order_by: {order: asc}, where: {is_display: {_eq: true}}) {\n      ...affiliationFragment\n      affiliations(order_by: {order: asc}, where: {is_display: {_eq: true}}) {\n        ...affiliationFragment\n        affiliations(order_by: {order: asc}, where: {is_display: {_eq: true}}) {\n          ...affiliationFragment\n          affiliations(order_by: {order: asc}, where: {is_display: {_eq: true}}) {\n            ...affiliationFragment\n            affiliations(order_by: {order: asc}, where: {is_display: {_eq: true}}) {\n              ...affiliationFragment\n              affiliations(order_by: {order: asc}, where: {is_display: {_eq: true}}) {\n                ...affiliationFragment\n                affiliations(order_by: {order: asc}, where: {is_display: {_eq: true}}) {\n                  ...affiliationFragment\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment affiliationFragment on affiliation {\n  id\n  name\n  parent_id\n  is_main\n}\n"])));
var USER_AFFILIATION_LIST = exports.USER_AFFILIATION_LIST = (0, _graphqlTag.default)(_templateObject9 || (_templateObject9 = (0, _taggedTemplateLiteral2.default)(["\nquery getUserAffiliation {\n  user_affiliation {\n    affiliation_id\n    user_id\n  }\n}\n"])));
var ZONE_INFO_AND_INDUSTRY_ZONE = exports.ZONE_INFO_AND_INDUSTRY_ZONE = (0, _graphqlTag.default)(_templateObject10 || (_templateObject10 = (0, _taggedTemplateLiteral2.default)(["\nquery zoneInfoAndIndustryZone($zoneID: String!) {\n  zone: zone_by_pk(id: $zoneID) {\n    need_convert_industry\n    app_allow_show_birthday\n  }\n  industryZone: industry_zones(where: {zone_id: {_eq: $zoneID}}) {\n    target_industry_code\n    zone_industry_code\n  }\n}\n"])));
var GET_USER_EXIST_BY_ID = exports.GET_USER_EXIST_BY_ID = (0, _graphqlTag.default)(_templateObject11 || (_templateObject11 = (0, _taggedTemplateLiteral2.default)(["\nquery getUserExistById($ids: _text!) {\n  users:get_user_exist_by_ids(args: {ids: $ids})\n  {\n    uid\n    id\n    zone_id\n  }\n}\n"])));