"use strict";

var _interopRequireDefault = require("/Users/vagrant/git/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.USER_AFFILIATION_INSERT_UPDATE = exports.USER_AFFILIATION_INSERT = exports.USER_AFFILIATION_DELETE = exports.UPDATE_MEMBER = exports.UPDATE_COMPANY_ADMIN = exports.UPDATE_COMPANY = exports.ADD_MEMBER = exports.ADD_IMPORT_MEMBER = exports.ADD_COMPANY = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("/Users/vagrant/git/node_modules/@babel/runtime-corejs2/helpers/taggedTemplateLiteral.js"));
var _graphqlTag = _interopRequireDefault(require("graphql-tag"));
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9;
var ADD_MEMBER = exports.ADD_MEMBER = (0, _graphqlTag.default)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\nmutation addMember($objects: [user_insert_input!]!) {\n  insert_user(objects: $objects) {\n      returning {\n        id\n        uid\n      }\n    }\n}\n"])));
var ADD_IMPORT_MEMBER = exports.ADD_IMPORT_MEMBER = (0, _graphqlTag.default)(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\nmutation importMember($objects: [user_insert_input!]!) {\n  insert_user(objects: $objects) {\n    affected_rows\n    returning {\n      id\n      uid\n    }\n  }\n}\n"])));
var ADD_COMPANY = exports.ADD_COMPANY = (0, _graphqlTag.default)(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)(["\nmutation addCompany($objects: [user_insert_input!]!) {\n    insert_user(objects: $objects) {\n      returning {\n        id\n      }\n    }\n}\n"])));
var UPDATE_MEMBER = exports.UPDATE_MEMBER = (0, _graphqlTag.default)(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2.default)(["\nmutation updateMember(\n  $uid: uuid,\n  $objects: user_set_input\n) {\n  update_user(where: {uid: {_eq: $uid}},\n    _set: $objects\n  ) {\n    affected_rows\n  }    \n}\n"])));
var UPDATE_COMPANY_ADMIN = exports.UPDATE_COMPANY_ADMIN = (0, _graphqlTag.default)(_templateObject5 || (_templateObject5 = (0, _taggedTemplateLiteral2.default)(["\nmutation updateCompany(\n  $id: Int\n  $name: String\n  $name_furigana: String\n  $phone_number: String\n  $mobile_phone: String\n  $fax: String\n  $email: String\n  $website: String\n  $postcode: String\n  $address_1: String\n  $address_2: String\n  $business_descriptions: String\n  $capital_stock: String\n  $employees: Int\n  $employees_part_time: Int\n  $established: date\n  $join_date: date\n  $industry: String\n) {\n  update_company(where: {id: {_eq: $id}},\n    _set: {\n      name: $name\n      name_furigana: $name_furigana\n      phone_number: $phone_number\n      mobile_phone: $mobile_phone\n      fax: $fax\n      email: $email\n      website: $website\n      postcode: $postcode\n      address_1: $address_1\n      address_2: $address_2\n      business_descriptions: $business_descriptions\n      capital_stock: $capital_stock\n      employees: $employees\n      employees_part_time: $employees_part_time\n      established: $established\n      join_date: $join_date\n      industry: $industry\n    }\n  ) {\n    affected_rows\n  }\n}\n"])));
var UPDATE_COMPANY = exports.UPDATE_COMPANY = (0, _graphqlTag.default)(_templateObject6 || (_templateObject6 = (0, _taggedTemplateLiteral2.default)(["\nmutation updateCompany(\n  $id: Int\n  $name: String\n  $name_furigana: String\n  $phone_number: String\n  $mobile_phone: String\n  $fax: String\n  $email: String\n  $website: String\n  $postcode: String\n  $address_1: String\n  $address_2: String\n  $business_descriptions: String\n  $capital_stock: String\n  $employees: Int\n  $established: date\n  $join_date: date\n) {\n  update_company(where: {id: {_eq: $id}},\n    _set: {\n      name: $name\n      name_furigana: $name_furigana\n      phone_number: $phone_number\n      mobile_phone: $mobile_phone\n      fax: $fax\n      email: $email\n      website: $website\n      postcode: $postcode\n      address_1: $address_1\n      address_2: $address_2\n      business_descriptions: $business_descriptions\n      capital_stock: $capital_stock\n      employees: $employees\n      established: $established\n      join_date: $join_date\n    }\n  ) {\n    affected_rows\n  }\n}\n"])));
var USER_AFFILIATION_INSERT = exports.USER_AFFILIATION_INSERT = (0, _graphqlTag.default)(_templateObject7 || (_templateObject7 = (0, _taggedTemplateLiteral2.default)(["\nmutation insertUserAffiliation($objects: [user_affiliation_insert_input!]!) {\n  insert_user_affiliation(objects: $objects, on_conflict: {constraint: user_affiliation_user_id_affiliation_id_key, update_columns: updated_at}) {\n    affected_rows\n  }\n}\n"])));
var USER_AFFILIATION_DELETE = exports.USER_AFFILIATION_DELETE = (0, _graphqlTag.default)(_templateObject8 || (_templateObject8 = (0, _taggedTemplateLiteral2.default)(["\nmutation deleteUserAffiliation($user_id: uuid!, $affiliation_ids: [Int!]!) {\n  delete_user_affiliation(where: {user_id: {_eq: $user_id}, affiliation_id: {_nin: $affiliation_ids}}) {\n    affected_rows\n  }\n}\n"])));
var USER_AFFILIATION_INSERT_UPDATE = exports.USER_AFFILIATION_INSERT_UPDATE = (0, _graphqlTag.default)(_templateObject9 || (_templateObject9 = (0, _taggedTemplateLiteral2.default)(["\nmutation userAffilication($user_id: uuid!, $objects: [user_affiliation_insert_input!]!,$affiliation_ids: [Int!]!) {\n  delete_user_affiliation(where: {user_id: {_eq: $user_id}, affiliation_id: {_nin: $affiliation_ids}}) {\n    affected_rows\n  }\n  insert_user_affiliation(objects: $objects, on_conflict: {constraint: user_affiliation_user_id_affiliation_id_key, update_columns: updated_at}) {\n    affected_rows\n    returning {\n      id\n    }\n  }\n}\n"])));