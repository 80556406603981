"use strict";

var _interopRequireDefault = require("/Users/vagrant/git/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getClaimToken = getClaimToken;
exports.getCompanyCode = getCompanyCode;
exports.getCompanyUID = getCompanyUID;
exports.getCouponCode = getCouponCode;
exports.getCouponCompanyCode = getCouponCompanyCode;
exports.getCouponEventCode = getCouponEventCode;
exports.getCouponIndexCode = getCouponIndexCode;
exports.getEditCompanyCode = getEditCompanyCode;
exports.getEventDetailCode = getEventDetailCode;
exports.getPerCompanyCode = getPerCompanyCode;
exports.getPerEventCode = getPerEventCode;
exports.getToken = getToken;
exports.removeCompanyCode = removeCompanyCode;
exports.removeCompanyUID = removeCompanyUID;
exports.removeCouponCode = removeCouponCode;
exports.removeCouponCompanyCode = removeCouponCompanyCode;
exports.removeCouponEventCode = removeCouponEventCode;
exports.removeCouponIndexCode = removeCouponIndexCode;
exports.removeEditCompanyCode = removeEditCompanyCode;
exports.removeEventDetailCode = removeEventDetailCode;
exports.removePerCompanyCode = removePerCompanyCode;
exports.removePerEventCode = removePerEventCode;
exports.removeSidebarStatus = removeSidebarStatus;
exports.removeSize = removeSize;
exports.removeToken = removeToken;
exports.setClaimToken = setClaimToken;
exports.setCompanyCode = setCompanyCode;
exports.setCompanyUID = setCompanyUID;
exports.setCouponCode = setCouponCode;
exports.setCouponCompanyCode = setCouponCompanyCode;
exports.setCouponEventCode = setCouponEventCode;
exports.setCouponIndexCode = setCouponIndexCode;
exports.setEditCompanyCode = setEditCompanyCode;
exports.setEventDetailCode = setEventDetailCode;
exports.setPerCompanyCode = setPerCompanyCode;
exports.setPerEventCode = setPerEventCode;
exports.setToken = setToken;
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var TokenKey = 'Admin-Token';
function getToken() {
  return _jsCookie.default.get(TokenKey);
}
function setToken(token) {
  return _jsCookie.default.set(TokenKey, token);
}
function removeToken() {
  return _jsCookie.default.remove(TokenKey);
}
function setCompanyCode(code) {
  return _jsCookie.default.set('CompanyCode', code);
}
function getCompanyCode() {
  return _jsCookie.default.get('CompanyCode');
}
function removeCompanyCode() {
  return _jsCookie.default.remove('CompanyCode');
}
function setPerCompanyCode(code) {
  return _jsCookie.default.set('PerCompanyCode', code);
}
function getPerCompanyCode() {
  return _jsCookie.default.get('PerCompanyCode');
}
function removePerCompanyCode() {
  return _jsCookie.default.remove('PerCompanyCode');
}
function setEventDetailCode(code) {
  return _jsCookie.default.set('EventDetailCode', code);
}
function getEventDetailCode() {
  return _jsCookie.default.get('EventDetailCode');
}
function removeEventDetailCode() {
  return _jsCookie.default.remove('EventDetailCode');
}
function setEditCompanyCode(code) {
  return _jsCookie.default.set('EditCompanyCode', code);
}
function getEditCompanyCode() {
  return _jsCookie.default.get('EditCompanyCode');
}
function removeEditCompanyCode() {
  return _jsCookie.default.remove('EditCompanyCode');
}
function setPerEventCode(code) {
  return _jsCookie.default.set('PerEventCode', code);
}
function getPerEventCode() {
  return _jsCookie.default.get('PerEventCode');
}
function removePerEventCode() {
  return _jsCookie.default.remove('PerEventCode');
}
function setCouponCode(code) {
  return _jsCookie.default.set('CouponCode', code);
}
function getCouponCode() {
  return _jsCookie.default.get('CouponCode');
}
function removeCouponCode() {
  return _jsCookie.default.remove('CouponCode');
}
function setCouponCompanyCode(code) {
  return _jsCookie.default.set('CouponCompanyCode', code);
}
function getCouponCompanyCode() {
  return _jsCookie.default.get('CouponCompanyCode');
}
function removeCouponCompanyCode() {
  return _jsCookie.default.remove('CouponCompanyCode');
}
function setCouponEventCode(code) {
  return _jsCookie.default.set('CouponEventCode', code);
}
function getCouponEventCode() {
  return _jsCookie.default.get('CouponEventCode');
}
function removeCouponEventCode() {
  return _jsCookie.default.remove('CouponEventCode');
}
function setCouponIndexCode(code) {
  return _jsCookie.default.set('CouponIndexCode', code);
}
function getCouponIndexCode() {
  return _jsCookie.default.get('CouponIndexCode');
}
function removeCouponIndexCode() {
  return _jsCookie.default.remove('CouponIndexCode');
}
function setCompanyUID(code) {
  return _jsCookie.default.set('CompanyUID', code);
}
function getCompanyUID() {
  return _jsCookie.default.get('CompanyUID');
}
function removeCompanyUID() {
  return _jsCookie.default.remove('CompanyUID');
}
function removeSidebarStatus() {
  return _jsCookie.default.remove('sidebarStatus');
}
function removeSize() {
  return _jsCookie.default.remove('sidebarStatus');
}
function setClaimToken(claim) {
  return _jsCookie.default.set('claim', claim);
}
function getClaimToken() {
  return _jsCookie.default.get('claim');
}