"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.zoneEnum = exports.keyExcel = void 0;
var zoneEnum = exports.zoneEnum = {
  Hiroshima: '1',
  Osaka: '2',
  Ehime: '3',
  Iwate: '4',
  Demo: '5',
  Nigata: '6',
  Ibaraki: '7'
};
var keyExcel = exports.keyExcel = {
  members: 'members'
};