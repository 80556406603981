"use strict";

var _interopRequireDefault = require("/Users/vagrant/git/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LOGIN = exports.GET_USER_DETAIL_INFO = exports.GET_USER = exports.FOUND_USER = exports.ACCOUNT_FOUND = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("/Users/vagrant/git/node_modules/@babel/runtime-corejs2/helpers/taggedTemplateLiteral.js"));
var _graphqlTag = _interopRequireDefault(require("graphql-tag"));
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;
var GET_USER = exports.GET_USER = (0, _graphqlTag.default)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\nquery($company_id: Int) {\n  user(where: {company_id: {_eq: $company_id}}) {\n    uid\n    avatar\n    position\n    company_id\n    mobile_phone\n    id\n    password\n    last_name\n    last_name_furigana\n    name\n    name_furigana\n    is_admin\n    is_active\n    is_display\n    created_at\n    updated_at\n  }\n}\n"])));
var FOUND_USER = exports.FOUND_USER = (0, _graphqlTag.default)(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\nquery($id: String) {\n  user(where: {id: {_eq: $id}}) {\n    uid\n    avatar\n    position\n    company_id\n    mobile_phone\n    id\n    password\n    last_name\n    last_name_furigana\n    name\n    name_furigana\n    is_admin\n    is_active\n    is_display\n    created_at\n    updated_at\n  }\n}\n"])));
var ACCOUNT_FOUND = exports.ACCOUNT_FOUND = (0, _graphqlTag.default)(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)(["\nquery($id: String) {\n  user(where: {id: {_eq: $id}}) {\n    uid                 # UID\n    company_id          # \u4F1A\u793EID\n    avatar              # \u30A2\u30D0\u30BF\u30FCURL\n    last_name           # \u82D7\u5B57\n    last_name_furigana  # \u82D7\u5B57\uFF08\u3075\u308A\u304C\u306A\uFF09\n    name                # \u540D\u524D\n    name_furigana       # \u540D\u524D\uFF08\u3075\u308A\u304C\u306A\uFF09\n    position            # \u5F79\u8077\n    mobile_phone        # \u643A\u5E2F\u756A\u53F7\n    id                  # \u30ED\u30B0\u30A4\u30F3ID\n    password            # \u30ED\u30B0\u30A4\u30F3\u30D1\u30B9\u30EF\u30FC\u30C9\n    is_admin            # \u6A29\u9650\uFF08true = \u7BA1\u7406\u8005\u3001false = \u901A\u5E38\uFF09\n  }\n}\n"])));
var LOGIN = exports.LOGIN = (0, _graphqlTag.default)(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2.default)(["\nquery($id: String, $password: String) {\n    # user(where: {id: {_eq: $id}, _and: {_and: {password: {_eq: $password}, _and: {is_active: {_eq: true}}}}}) {\n    user(where: {id: {_eq: $id}, _and: {_and: {password: {_eq: $password}}}}) {\n      uid                 # UID\n    company_id          # \u4F1A\u793EID\n    avatar              # \u30A2\u30D0\u30BF\u30FCURL\n    last_name           # \u82D7\u5B57\n    last_name_furigana  # \u82D7\u5B57\uFF08\u3075\u308A\u304C\u306A\uFF09\n    name                # \u540D\u524D\n    name_furigana       # \u540D\u524D\uFF08\u3075\u308A\u304C\u306A\uFF09\n    position            # \u5F79\u8077\n    mobile_phone        # \u643A\u5E2F\u756A\u53F7\n    id                  # \u30ED\u30B0\u30A4\u30F3ID\n    password            # \u30ED\u30B0\u30A4\u30F3\u30D1\u30B9\u30EF\u30FC\u30C9\n    is_admin            # \u6A29\u9650\uFF08true = \u7BA1\u7406\u8005\u3001false = \u901A\u5E38\uFF09\n    is_active           # \u30ED\u30B0\u30A4\u30F3\u53EF\u5426\uFF08true=\u53EF\u3001false=\u5426\uFF09\n    is_display          # \u4E00\u89A7\u8868\u793A\u975E\u8868\u793A\uFF08true=\u8868\u793A\u3001false=\u975E\u8868\u793A\uFF09\n  }\n}\n"])));
var GET_USER_DETAIL_INFO = exports.GET_USER_DETAIL_INFO = (0, _graphqlTag.default)(_templateObject5 || (_templateObject5 = (0, _taggedTemplateLiteral2.default)(["\nquery GET_USER_DETAIL_INFO($uid: uuid!) {\n  user:user_by_pk(uid: $uid) {\n    zone {\n      app_allow_show_birthday\n    }\n  }\n}\n"])));