"use strict";

var _interopRequireDefault = require("/Users/vagrant/git/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.depthFirstSearch = depthFirstSearch;
exports.isArray = isArray;
exports.isExternal = isExternal;
exports.isString = isString;
exports.validAlphabets = validAlphabets;
exports.validEmail = validEmail;
exports.validLowerCase = validLowerCase;
exports.validURL = validURL;
exports.validUpperCase = validUpperCase;
exports.validUsername = validUsername;
exports.year_month_day = year_month_day;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.set");
var _toConsumableArray2 = _interopRequireDefault(require("/Users/vagrant/git/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
require("core-js/modules/es6.array.find");
require("core-js/modules/es6.regexp.to-string");
var _moment = _interopRequireDefault(require("moment"));
/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * @param {string} path
 * @returns {Boolean}
 */

function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}

/**
 * ログイン時のユーザ名はメールアドレスのみ
 * @param {string} str
 * @returns {Boolean}
 */
function validUsername(str) {
  return str.trim().length > 0;
}

/**
 * @param {string} url
 * @returns {Boolean}
 */
function validURL(url) {
  var reg = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
  return reg.test(url);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
function validLowerCase(str) {
  var reg = /^[a-z]+$/;
  return reg.test(str);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
function validUpperCase(str) {
  var reg = /^[A-Z]+$/;
  return reg.test(str);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
function validAlphabets(str) {
  var reg = /^[A-Za-z]+$/;
  return reg.test(str);
}

/**
 * @param {string} email
 * @returns {Boolean}
 */
function validEmail(email) {
  var reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return reg.test(email);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
function isString(str) {
  if (typeof str === 'string' || str instanceof String) {
    return true;
  }
  return false;
}

/**
 * @param {Array} arg
 * @returns {Boolean}
 */
function isArray(arg) {
  if (typeof Array.isArray === 'undefined') {
    return Object.prototype.toString.call(arg) === '[object Array]';
  }
  return Array.isArray(arg);
}
function depthFirstSearch(affiliations, affiliation, isLoadFromData, inputIsListNumber) {
  // inputIsListNumber ExcelファイルのインポートからのID
  // isLoadFromData ユーザーからのデータ
  var result = [];
  var treeMapAffiliation = [];
  function indexWhere(array, conditionFn) {
    var item = array.find(conditionFn);
    return array.indexOf(item);
  }
  affiliations.map(function (item) {
    return affiliationSearch(item);
  });
  function affiliationSearch(object) {
    if (treeMapAffiliation) {
      var indexOfTreeAffiliation = indexWhere(treeMapAffiliation, function (item) {
        return item.parent_id === object.parent_id;
      });
      if (indexOfTreeAffiliation >= 0) {
        treeMapAffiliation.splice(indexOfTreeAffiliation, treeMapAffiliation.length);
      }
    }
    treeMapAffiliation.push(object);
    if (affiliation.length !== 0 && affiliation) {
      var checkNotEmpty = affiliation.filter(function (item) {
        return (isLoadFromData ? item.affiliation_id.toString() : inputIsListNumber ? item.trim().toString() : item.id.toString()) === object.id.toString();
      });
      if (checkNotEmpty.length !== 0) {
        treeMapAffiliation.forEach(function (item) {
          var indexOfFloor = treeMapAffiliation.indexOf(item);
          item.paddingFloorInTreeAffiliation = indexOfFloor * 24;
          result.push(item);
        });
      }
    }
    return object.affiliations.map(function (ite) {
      return affiliationSearch(ite);
    });
  }
  return (0, _toConsumableArray2.default)(new Set(result));
}
function year_month_day(val) {
  if (val) {
    return (0, _moment.default)(String(val)).format('YYYY/MM/DD');
  } else {
    return '';
  }
}