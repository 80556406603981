"use strict";

var _interopRequireWildcard = require("/Users/vagrant/git/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.osakaText = exports.nigataText = exports.iwateText = exports.ibarakiText = exports.hiroshimaText = exports.ehimeText = exports.demoText = void 0;
exports.textChangeByZone = textChangeByZone;
var enums = _interopRequireWildcard(require("./enums"));
var _auth = require("./auth");
function textChangeByZone() {
  var zoneId = (0, _auth.getClaimToken)().trim();
  switch (zoneId) {
    case enums.zoneEnum.Hiroshima:
      return hiroshimaText;
    case enums.zoneEnum.Osaka:
      return osakaText;
    case enums.zoneEnum.Ehime:
      return ehimeText;
    case enums.zoneEnum.Iwate:
      return iwateText;
    case enums.zoneEnum.Demo:
      return demoText;
    case enums.zoneEnum.Ibaraki:
      return ibarakiText;
    case enums.zoneEnum.Nigata:
      return nigataText;
    default:
      return hiroshimaText;
  }
}
var hiroshimaText = exports.hiroshimaText = {
  // create, edit page
  textBranchAreaByZone: '支部&地区',
  branchMassage: '支部',
  areaMessage: '地区',
  // import page
  importSample: 'assets/import_sample.xlsx',
  branchName: '広島',
  exampleName: '広島東支部の広島東支部理事会(1103)',
  exampleNumber: '1064, 1103',
  exampleBranchName: '広島中支部',
  exampleFirst: '中1地区会',
  exampleLast: '中1地区会役員会',
  number1: '1077',
  number2: '1064'
};
var osakaText = exports.osakaText = {
  // create, edit page
  textBranchAreaByZone: 'ブロック&支部',
  branchMassage: 'ブロック',
  areaMessage: '支部',
  // import page
  importSample: 'assets/import_sample.xlsx',
  branchName: '大阪',
  exampleName: '組織委員会(2150)',
  exampleNumber: '2067, 2150',
  exampleBranchName: '大阪北B',
  exampleFirst: '豊能支部',
  exampleLast: '幹事会',
  number1: '2069',
  number2: '2067'
};
var ehimeText = exports.ehimeText = {
  // create, edit page
  textBranchAreaByZone: '支部&地区',
  branchMassage: '支部',
  areaMessage: '地区',
  // import page
  importSample: 'assets/import_sample.xlsx',
  branchName: '愛媛',
  exampleName: '支部の松山支部第１地区会(3022)',
  exampleNumber: '3002, 3022',
  exampleBranchName: '理事会',
  exampleFirst: '委員会',
  exampleLast: '地域共生委員会',
  number1: '3007',
  number2: '3002'
};
var iwateText = exports.iwateText = {
  // create, edit page
  textBranchAreaByZone: '支部&地区',
  branchMassage: '支部',
  areaMessage: '地区',
  // import page
  importSample: 'assets/import_sample.xlsx',
  branchName: '岩手',
  exampleName: '支部の松山支部第１地区会(3022)',
  exampleNumber: '4003, 4032',
  exampleBranchName: '県央支部',
  exampleFirst: '理事会',
  exampleLast: '青年部会',
  number1: '4009',
  number2: '4026'
};
var demoText = exports.demoText = {
  // create, edit page
  textBranchAreaByZone: '支部&地区',
  branchMassage: '支部',
  areaMessage: '地区',
  // import page
  importSample: 'assets/import_sample.xlsx',
  branchName: '広島',
  exampleName: '広島東支部の広島東支部理事会(1103)',
  exampleNumber: '1064, 1103',
  exampleBranchName: '広島中支部',
  exampleFirst: '中1地区会',
  exampleLast: '中1地区会役員会',
  number1: '1077',
  number2: '1064'
};
var nigataText = exports.nigataText = {
  // create, edit page
  textBranchAreaByZone: '支部&地区',
  branchMassage: '支部',
  areaMessage: '地区',
  // import page
  importSample: 'assets/import_sample.xlsx',
  branchName: '新潟',
  exampleName: '',
  exampleNumber: '',
  exampleBranchName: '',
  exampleFirst: '',
  exampleLast: '',
  number1: '',
  number2: ''
};
var ibarakiText = exports.ibarakiText = {
  // create, edit page
  textBranchAreaByZone: '支部&地区',
  branchMassage: '支部',
  areaMessage: '地区',
  // import page
  importSample: 'assets/import_sample.xlsx',
  branchName: '茨城',
  exampleName: '',
  exampleNumber: '',
  exampleBranchName: '',
  exampleFirst: '',
  exampleLast: '',
  number1: '',
  number2: ''
};